* {
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 100%;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
}

.viewport-height {
  height: 100vh;
  max-height: 100vh;
  height: -webkit-fill-available;
  max-height: -webkit-fill-available;
}

.lookiero .main {
  opacity: 0;
}
.loaded .main-content {
  animation-name: fadein;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
